<template>
  <div class="top-bar no_mobile">
    <div class="d-flex justify-content-center align-items-center ">
      <div class="top-bar__item mx-md-5 d-flex align-items-center" v-for="icon in globalOptions?.topIcons?.nodes">
        <img :src="icon?.icon?.node?.sourceUrl" :alt="icon?.icon?.node?.altText" class="md-2"/>
        {{ icon?.text }}
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const {globalOptions} = useGlobalStore();

</script>
